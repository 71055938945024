<template>
  <div>
    <div class="form-body" style="padding-top: 60rem; padding-left: 40rem">
      <el-form ref="form" label-width="140rem">
        <el-form-item label="特批类型">
          <p>{{ info.type_tex }}</p>
        </el-form-item>
        <el-form-item label="学生身份证号">
          <p>{{ info.stu_id_card }}（{{ info.stu_name }}）</p>
        </el-form-item>
        <el-form-item label="商品订单号">
          <p>{{ info.order_number }}</p>
        </el-form-item>
        <el-form-item label="钉钉审批单号">
          <p>{{ info.approval_num }}</p>
        </el-form-item>
        <template v-if="info.type === 1">
          <el-row class="zhu-row-line">
            <el-col :span="8">
              <div class="label">应缴纳金额</div>
              <div>{{ info.privilege_before }}</div>
            </el-col>
            <el-col :span="8">
              <div class="label">减免金额</div>
              <div>{{ info.privilege_val }}</div>
            </el-col>
            <el-col :span="8">
              <div class="label">实缴金额</div>
              <div>{{ info.privilege_after }}</div>
            </el-col>
          </el-row>
        </template>
        <template v-else-if="info.type === 2">
          <el-form-item label="分期计划">
            <p>{{ info.privilege_val }}</p>
          </el-form-item>
        </template>
        <el-form-item ref="img-box" label="特批审批凭证">
          <el-image style="width: 200rem; height: 120rem" :src="info.approval_proof" :preview-src-list="[info.approval_proof]" :fit="'fit'"></el-image>
        </el-form-item>
        <el-row class="zhu-row-line">
          <el-col :span="8">
            <div class="label">创建人</div>
            <div>{{ info.creator }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">创建时间</div>
            <div>{{ info.created_at }}</div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="footer-button">
      <el-button type="primary" @click="$router.back()">返回</el-button>
    </div>
  </div>
</template>
<script>
import moment from 'moment'

export default {
	_config:{"route":{"path":"details","meta":{"title":"详情"}}},
  data() {
    return {
      info: {}
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$_register('api/recruit/special-privilege/show?id=' + this.$route.query.id).then(res => {
        this.info = res.data.data
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.zhu-row-line {
  margin-bottom: 22rem;
  font-size: 14rem;
  line-height: 40rem;
  .label {
    width: 140rem;
    padding-right: 12rem;
    margin-right: 30rem;
    text-align: right;
    vertical-align: middle;
    float: left;
    color: #666;
  }
}
</style>
